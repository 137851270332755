const colors = {
  background: {
    dark: '#1b1c1d',
    blue: '#2185d0',
    darkBlue: '#1678c2',
  },
  gray: 'rgba(255,255,255,.1)',
  gray900: 'rgba(255, 255, 255, 0.9)',
  green: '#00b5ad',
  darkGreen: '#009c95',
  white: '#fff',
};

export default colors;
