import styled from 'styled-components';

export const H3 = styled.h3`
  font-size: 28px;
  font-weight: 800;
`;

export const P = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

export const Caption = styled.p`
  font-size: 13px;
`;
