import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  width: 100%;
  background: ${(props) => props.theme.colors.background.dark};
  padding-top: 1rem;
`;

export const NavList = styled.ul<{ isOpen: boolean }>`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    flex-flow: column;
    position: fixed;
    width: 250px;
    top: 0;
    left: ${(props) => (props.isOpen ? 0 : -250)}px;
    bottom: 0;
    background: ${(props) => props.theme.colors.background.dark};
    z-index: 99999;
    transition: left 300ms ease-in;
  }
`;

export const NavListItem = styled.li`
  color: ${(props) => props.theme.colors.gray900};

  a {
    display: flex;
    text-decoration: none;
    font-weight: 700;
    line-height: 42px;
    padding: 0 18px;
    margin-bottom: -2px;
    font-size: 15px;
    color: inherit;

    &.active {
      border-bottom: 2px solid ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.white};
    }

    &:hover {
      color: ${(props) => props.theme.colors.white};
    }
  }

  @media (max-width: 768px) {
    a {
      border: 1px solid rgba(255, 255, 255, 0.08);

      &.active {
        background: #3d3e3f;
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.08);
      }
    }
  }
`;

export const SidebarOverlay = styled.div<{ isOpen: boolean }>`
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, ${(props) => (props.isOpen ? 0.2 : 0)});
  z-index: ${(props) => (props.isOpen ? 99988 : -1)};
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
