import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Button from '../../components/common/Button';
import { H3, P } from '../../components/common/Typography';
import { Header, HeaderTopHero, Description, LeftColumn, RightColumn, FeatureSection } from './Home.styled';

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header>
        <HeaderTopHero>
          <img src="/logo.png" />
          <h1>RedVox, Inc</h1>
        </HeaderTopHero>
        <Description>Reimagining ubiquitous sensor data collection...</Description>
        <Button variant="standard" onClick={() => navigate('/registration')}>
          Get Started
          <FaArrowRight />
        </Button>
      </Header>
      <FeatureSection className="container">
        <div className="row">
          <LeftColumn className="col-12 col-md-6">
            <H3>Ubiquitous Sensing Platforms</H3>
            <P>
              The RedVox&nbsp;
              <a href="https://play.google.com/store/apps/details?id=io.redvod.InfraSoundRecorder">Android</a>
              &nbsp;and&nbsp;
              <a href="https://apps.apples.com/us/app/infrasound-recorder/id969566810">iOS</a>&nbsp;apps can turn any
              smartphone into an intelligent sensing platform capable of recording infrasound, pressure, and more!
            </P>
            <H3>Sensor Analysis</H3>
            <P className="mb-0">
              RedVox provides support for analyzing sensor data through this Cloud Platform and through the open
              source&nbsp;
              <a href="https://github.com/RedVoxInc/redvox-python-sdk">RedVox Python SDK</a>.
            </P>
          </LeftColumn>
          <RightColumn className="col-12 col-md-6">
            <img src="/android_screenshot.png" />
          </RightColumn>
        </div>
      </FeatureSection>
    </>
  );
};

export default Home;
