/**
 * This module contains code for interacting with external API endpoints.
 *
 * We utilize axios (https://github.com/axios/axios)
 * for HTTP requests and WebSockets (https://developer.mozilla.org/en-US/docs/Web/API/WebSocket) for streaming types.
 */
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
// import { RegistrationForm } from '../ui/Registration';
import {
  AuthReq,
  AuthResp,
  DeleteAccountReq,
  PasswordResetReq,
  PasswordResetResp,
  UpdatePasswordReq,
  UpdatePasswordResp,
  VerifiedToken,
} from './http_types/auth';
import { DataReq, StreamingDataResponse } from './http_types/data';
import {
  AugmentReportReq,
  BuildReportReq,
  CopyReportReq,
  CopyReportResp,
  DeleteReportResp,
  ReportReq,
  ReportResp,
  ReportServerReq,
  ReportsReq,
  ReportsResp,
  StreamingBuildReportResponse,
  UpdateReportReq,
  UpdateReportResp,
} from './http_types/reports';
import { StationStatusReq, StationStatusResp } from './http_types/station_status';
import { RegistrationForm } from '../pages/Auth/Register';
import { UserSettingReq, UserSettingRes } from './http_types/settings';
import { EmptyOrErrorResp } from './http_types/common';

enum RemoteEndpoints {
  AuthenticateUser = '/api/v2/auth',
  RefreshAuthentication = '/api/v2/auth/refresh',
  Logout = '/api/v2/auth/logout',
  DataReq = '/api/v2/data/range',
  Registration = '/api/v2/auth/register',
  StationStatus = '/api/v2/metadata/timeline',
  VerifyRegistration = '/api/v2/auth/verify',
  RequestPasswordReset = '/api/v2/auth/request_reset',
  PerformPasswordReset = '/api/v2/auth/reset',
  BuildReportReq = '/api/v2/report/ws',
  ReportsReq = '/api/v2/reports',
  ReportReq = '/api/v2/report',
  DeleteReport = '/api/v2/delete_report',
  ModifyReport = '/api/v2/modify_report',
  CopyReport = '/api/v2/copy_report',
  FetchUserSettings = '/api/v2/settings',
  UpdateUserSettings = '/api/v2/settings/update',
  DeleteAccount = '/api/v2/account/delete',
}

const FIVE_MINUTES_MS: number = 5 * 60 * 1000;

/**
 * Represents valid API choices when making certain requests.
 */
export enum ApiChoice {
  Api900 = 'Api900',
  Api1000 = 'Api1000',
  Both = 'Both',
}

/**
 * A registration response. A null error is assumed to be an ok response.
 */
export interface RegistrationResp {
  error: string | null;
}

/**
 * Tests if the provided value is null or undefined, and if it is, returns the default.
 * @param val The value to test.
 * @param def The default value to use if val is undefined or null.
 */
const nullOrUndefined = <T, R>(val: T | null | undefined, def: R): T | R => {
  if (val === null || val === undefined) {
    return def;
  }
  return val;
};

export const CANCEL = 'cancel';

/**
 * An HTTP client and WebSocket client for communication with RedVox API endpoints.
 */
export class CloudClient {
  private readonly instance: AxiosInstance;
  private readonly onAuthChange: (verifiedToken: VerifiedToken | null) => void;
  private readonly wsBaseUrl: string;
  private readonly setInitializing: (isInitializing: boolean) => void;
  private authResp: AuthResp | null = null;

  /**
   * Builds an instance of this client.
   * @param httpBaseUrl Base URL of HTTP requests.
   * @param wsBaseUrl Base URL of WebSocket requests.
   * @param onAuthChange Handler that fires when authentication changes
   * @param setInitializing Sets if this client is still initializing or not (has the refresh process finished?)
   */
  public constructor(
    httpBaseUrl: string,
    wsBaseUrl: string,
    onAuthChange: (verifiedToken: VerifiedToken | null) => void,
    setInitializing: (isInitializing: boolean) => void
  ) {
    this.setInitializing = setInitializing;
    this.instance = axios.create({ baseURL: httpBaseUrl });
    this.onAuthChange = onAuthChange;
    this._initializeResponseInterceptor();
    this.wsBaseUrl = wsBaseUrl;

    // try to log back in if the refresh token is still in a cookie
    this.refreshUser();
  }

  /**
   * Authenticates a user with the RedVox cloud.
   * @param authReq The user authentication request.
   * @param cb A callback that is ran showing if a user is authenticated or not.
   */
  public authenticateUser(authReq: AuthReq, cb: (authenticated: boolean) => void): void {
    this.instance
      .post<AuthResp, AuthResp>(RemoteEndpoints.AuthenticateUser, authReq, {
        withCredentials: true,
      })
      .then((resp) => {
        this.updateAuthResp(resp);
        // this.refreshUser();
        cb(true);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        this.updateAuthResp(null);
        cb(false);
      });
  }

  /**
   * Called when an auth response. Updates the authentication in the App root.
   * @param authResp The AuthResp.
   * @private
   */
  private updateAuthResp(authResp: AuthResp | null): void {
    const verifiedToken = nullOrUndefined(authResp?.claims, null);
    this.authResp = authResp;
    this.onAuthChange(verifiedToken);
  }

  /**
   * Attempts to refresh the user's authentication.
   */
  public refreshUser(): void {
    this.instance
      .get<AuthResp, AuthResp>(RemoteEndpoints.RefreshAuthentication, { withCredentials: true })
      .then((resp) => {
        this.updateAuthResp(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        this.updateAuthResp(null);
      })
      .finally(() => {
        this.setInitializing(false);
      });
  }

  /**
   * Signs the user out.
   */
  public signOut(): void {
    this.instance
      .get<string, string>(RemoteEndpoints.Logout, { withCredentials: true })
      .then(() => {
        this.updateAuthResp(null);
        setTimeout(() => this.refreshUser(), FIVE_MINUTES_MS);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        this.updateAuthResp(null);
      });
  }

  /**
   * Requests a raw data download. A WebSocket connection is created to provide progress updates and the final
   * response.
   * @param dataReq An instance of a DataReq.
   * @param cb A callback that either returns a response or null.
   */
  public requestData(dataReq: DataReq, cb: (res: StreamingDataResponse | null) => void): void {
    dataReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    const ws = new WebSocket(`${this.wsBaseUrl}${RemoteEndpoints.DataReq}`);
    let sentResp = false;

    // Once the connection is opened, send the data request.
    ws.addEventListener('open', () => {
      ws.send(JSON.stringify(dataReq));
    });

    // Handle server responses
    ws.addEventListener('message', (event) => {
      sentResp = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const resp: StreamingDataResponse = JSON.parse(event.data) as StreamingDataResponse;
      cb(resp);
    });

    // Handle error responses
    ws.addEventListener('error', (event) => {
      cb(null);
      console.log('onError', event);
    });

    // Handle close responses
    ws.addEventListener('close', () => {
      if (!sentResp) {
        cb(null);
      }
    });
  }

  /**
   * Performs a registration request.
   * @param registrationForm An instance of the registration form.
   * @param cb A callback that contains an optional error.
   */
  public register(registrationForm: RegistrationForm, cb: (error: string | null) => void): void {
    this.instance
      .post<RegistrationResp, RegistrationResp>(RemoteEndpoints.Registration, registrationForm)
      .then((resp) => {
        cb(resp.error);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb(reason.message);
      });
  }

  /**
   * Performs a station status request.
   * @param stationStatusReq An instance of a station status request.
   * @param cb A callback that contains the response or null.
   */
  public stationStatus(stationStatusReq: StationStatusReq, cb: (error: StationStatusResp | null) => void): void {
    stationStatusReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    this.instance
      .post<StationStatusResp, StationStatusResp>(RemoteEndpoints.StationStatus, stationStatusReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb({ err: reason.message, segments: [] });
      });
  }

  /**
   * Performs a verification of a registration token.
   * @param token The token to verify
   * @param cb A callback providing an optional error
   */

  public verify(token: string, cb: (error: string | null) => void): void {
    this.instance
      .get<RegistrationResp, RegistrationResp>(`${RemoteEndpoints.VerifyRegistration}/${token}`)
      .then((resp) => {
        cb(resp.error);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb(reason.message);
      });
  }

  /**
   * Performs a verification of a registration token.
   * @param email The token to verify
   * @param cb A callback providing an optional error
   */
  public requestPasswordReset(email: string, cb: (error: string | null) => void): void {
    const data: PasswordResetReq = { email };
    this.instance
      .post<PasswordResetResp, PasswordResetResp>(RemoteEndpoints.RequestPasswordReset, data)
      .then((resp) => {
        cb(resp.error);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb(reason.message);
      });
  }

  public performPasswordReset(updatePasswordReq: UpdatePasswordReq, cb: (error: string | null) => void): void {
    this.instance
      .post<UpdatePasswordResp, UpdatePasswordResp>(RemoteEndpoints.PerformPasswordReset, updatePasswordReq)
      .then((resp) => {
        cb(resp.error);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb(reason.message);
      });
  }

  public requestBuildReport(
    reportReq: BuildReportReq,
    cb: (res: [StreamingBuildReportResponse, WebSocket] | null) => void
  ): void {
    reportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    const ws = new WebSocket(`${this.wsBaseUrl}${RemoteEndpoints.BuildReportReq}`);
    let sentResp = false;

    // Once the connection is opened, send the data request.
    ws.addEventListener('open', () => {
      const reportServerReq: ReportServerReq = { build_report_req: reportReq };
      ws.send(JSON.stringify(reportServerReq));
    });

    // Handle server responses
    ws.addEventListener('message', (event) => {
      sentResp = true;
      // NaNs aren't valid JSON...
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const d = event.data.replaceAll('NaN', '0.0');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const resp: StreamingBuildReportResponse = JSON.parse(d) as StreamingBuildReportResponse;
      cb([resp, ws]);
    });

    // Handle error responses
    ws.addEventListener('error', (event) => {
      cb(null);
      console.log('onError', event);
    });

    // Handle close responses
    ws.addEventListener('close', () => {
      if (!sentResp) {
        cb(null);
      }
    });
  }

  public requestAugmentReport(
    reportReq: AugmentReportReq,
    cb: (res: [StreamingBuildReportResponse, WebSocket] | null) => void
  ): void {
    reportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    const ws = new WebSocket(`${this.wsBaseUrl}${RemoteEndpoints.BuildReportReq}`);
    let sentResp = false;

    // Once the connection is opened, send the data request.
    ws.addEventListener('open', () => {
      const reportServerReq: ReportServerReq = { augment_report_req: reportReq };
      ws.send(JSON.stringify(reportServerReq));
    });

    // Handle server responses
    ws.addEventListener('message', (event) => {
      sentResp = true;
      // NaNs aren't valid JSON...
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const d = event.data.replaceAll('NaN', '0.0');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const resp: StreamingBuildReportResponse = JSON.parse(d) as StreamingBuildReportResponse;
      cb([resp, ws]);
    });

    // Handle error responses
    ws.addEventListener('error', (event) => {
      cb(null);
      console.log('onError', event);
    });

    // Handle close responses
    ws.addEventListener('close', () => {
      if (!sentResp) {
        cb(null);
      }
    });
  }

  public requestReport(reportReq: ReportReq, cb: (resp: ReportResp) => void): void {
    reportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, 'public');
    this.instance
      .post<ReportResp, ReportResp>(RemoteEndpoints.ReportReq, reportReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        if (reason.response?.status === 401) {
          cb({ err: 'unauthorized', report: null });
        } else {
          cb({ err: `${reason.toString()}`, report: null });
        }
      });
  }

  public requestReports(reportsReq: ReportsReq, cb: (resp: ReportsResp) => void): void {
    reportsReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    this.instance
      .post<ReportsResp, ReportsResp>(RemoteEndpoints.ReportsReq, reportsReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb({ err: `${reason.toString()}`, reports: [], reports_req: reportsReq });
      });
  }

  public deleteReport(reportReq: ReportReq, cb: (resp: DeleteReportResp) => void): void {
    reportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    this.instance
      .post<DeleteReportResp, DeleteReportResp>(RemoteEndpoints.DeleteReport, reportReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb({ err: `${reason.toString()}` });
      });
  }

  public deleteAccount(cb: (resp: EmptyOrErrorResp) => void): void {
    const deleteAccountReq: DeleteAccountReq = { auth_token: nullOrUndefined(this.authResp?.auth_token, '') };
    this.instance
      .post<DeleteAccountReq, EmptyOrErrorResp>(RemoteEndpoints.DeleteAccount, deleteAccountReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        // cb({reason.toString() );
      });
  }

  public modifyReport(updateReportReq: UpdateReportReq, cb: (resp: UpdateReportResp) => void): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    updateReportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    this.instance
      .post<UpdateReportResp, UpdateReportResp>(RemoteEndpoints.ModifyReport, updateReportReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb({ err: `${reason.toString()}` });
      });
  }

  public copyReport(reportReq: CopyReportReq, cb: (resp: CopyReportResp) => void): void {
    reportReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');
    this.instance
      .post<CopyReportResp, CopyReportResp>(RemoteEndpoints.CopyReport, reportReq)
      .then((resp) => {
        cb(resp);
      })
      .catch((reason: AxiosError) => {
        console.error(reason);
        cb({ error: `${reason.toString()}`, report_id: null });
      });
  }

  public async fetchUserSettings(): Promise<UserSettingRes> {
    const userSettingsReq = {
      auth_token: nullOrUndefined(this.authResp?.auth_token, ''),
    };

    return await this.instance.post<UserSettingReq, UserSettingRes>(RemoteEndpoints.FetchUserSettings, userSettingsReq);
  }

  public async updateUserSettings(userSettingsReq: UserSettingReq): Promise<any> {
    userSettingsReq.auth_token = nullOrUndefined(this.authResp?.auth_token, '');

    return await this.instance.post<UserSettingReq, any>(RemoteEndpoints.UpdateUserSettings, userSettingsReq);
  }

  // Axios typescript magic
  private _initializeResponseInterceptor = (): void => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  private _handleResponse = ({ data }: AxiosResponse) => data;
  private _handleError = (error: AxiosError): AxiosPromise => Promise.reject(error);
}
