import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import TopNav from 'components/TopNav';
import Home from 'pages/Home';
import { useCloudContext } from 'libs/CloudContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Public Pages
const PrivacyPage = React.lazy(() => import('./pages/Privacy'));
const TermsPage = React.lazy(() => import('./pages/Terms'));
// Auth Pages
const Login = React.lazy(() => import('./pages/Auth/Login'));
const Register = React.lazy(() => import('./pages/Auth/Register'));
const ResetPasswordRequest = React.lazy(() => import('./pages/Auth/ResetPasswordRequest'));
const ResetPasswordConfirm = React.lazy(() => import('./pages/Auth/ResetPasswordConfirm'));
const Account = React.lazy(() => import('./pages/Auth/Account'));
const Verification = React.lazy(() => import('./pages/Auth/Verification'));
const DeleteAccount = React.lazy(() => import('./pages/Auth/DeleteAccount'));
const DeleteAccountForm = React.lazy(() => import('./pages/Auth/DeleteAccountForm'));
// Protected Pages
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const ReportList = React.lazy(() => import('./pages/ReportList'));
const ReportDetail = React.lazy(() => import('./pages/ReportDetail'));

function App() {
  return (
    <div className="App">
      <TopNav />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route
          path="/login"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/registration"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/registrationMobile"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path="/passwordReset"
          element={
            <Suspense fallback={<>Loading...</>}>
              <ResetPasswordRequest />
            </Suspense>
          }
        />
        <Route
          path="/passwordResetMobile"
          element={
            <Suspense fallback={<>Loading...</>}>
              <ResetPasswordRequest />
            </Suspense>
          }
        />
        <Route
          path="/reset/:token"
          element={
            <Suspense fallback={<>Loading...</>}>
              <ResetPasswordConfirm />
            </Suspense>
          }
        />
        <Route
          path="/account"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Account />
            </Suspense>
          }
        />
        <Route
          path="/accountDelete"
          element={
            <Suspense fallback={<>Loading...</>}>
              <DeleteAccount />
            </Suspense>
          }
        />
        <Route
          path="/accountDeleteForm"
          element={
            <Suspense fallback={<>Loading...</>}>
              <DeleteAccountForm />
            </Suspense>
          }
        />
        <Route
          path="/reports"
          element={
            <Suspense fallback={<>Loading...</>}>
              <ReportList />
            </Suspense>
          }
        />
        <Route
          path="/verify/:token"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Verification />
            </Suspense>
          }
        />
        <Route
          path="/reports/:id"
          element={
            <Suspense fallback={<>Loading...</>}>
              <ReportDetail />
            </Suspense>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<>Loading...</>}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<>Loading...</>}>
              <PrivacyPage />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<>Loading...</>}>
              <TermsPage />
            </Suspense>
          }
        />
        <Route path="/" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
}

export default App;
