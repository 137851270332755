import React, { createContext, useContext, useState, useEffect } from 'react';
import { CloudClient } from './CloudClient';
import { loadConfig } from './config';
import { VerifiedToken } from './http_types/auth';

interface ContextValues {
  auth: VerifiedToken | null;
  cloudClient: CloudClient;
  cloudInitializing: boolean;
}

const CloudContext = createContext<ContextValues>({
  auth: null,
  cloudClient: {} as CloudClient,
  cloudInitializing: true,
});
export const useCloudContext = (): ContextValues => useContext(CloudContext);

interface CloudProviderProps {
  children: React.ReactNode;
}

const config = loadConfig();

export const CloudProvider = ({ children }: CloudProviderProps) => {
  const [getAuth, setAuth] = useState<VerifiedToken | null>(null);
  const [cloudClient, setCloudClient] = useState<CloudClient>({} as CloudClient);
  const [initializing, setInitializing] = useState<boolean>(true);

  useEffect(() => {
    if (config) {
      const client = new CloudClient(config.httpBaseUrl, config.wsBaseUrl, setAuth, setInitializing);
      setInterval(() => client.refreshUser(), config.refreshAuthIntervalS * 1_000.0);
      setCloudClient(client);
    }
  }, [config]);
  return (
    <CloudContext.Provider value={{ auth: getAuth, cloudClient, cloudInitializing: initializing }}>
      {children}
    </CloudContext.Provider>
  );
};
