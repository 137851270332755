/**
 * This module provides type safe runtime configurations.
 *
 * Configuration variables are passed into the environment with https://github.com/andrewmclagan/react-env
 */

import env from '@beam-australia/react-env';

// Define configuration keys and constants
const CONFIG_HTTP_API = 'HTTP_API';
const CONFIG_WS_API = 'WS_API';
const CONFIG_POST_REGISTRATION_VERIFICATION_REDIRECT = 'POST_REGISTRATION_VERIFICATION_REDIRECT';
const CONFIG_DISPLAY_RESPONSIVE_CONTAINER = 'DISPLAY_RESPONSIVE_CONTAINER';
const CONFIG_REFRESH_AUTH_INTERVAL_S = 'REFRESH_AUTH_INTERVAL_S';
const CONFIG_MAX_QUERY_DURATION_ENTERPRISE_S = 'MAX_QUERY_DURATION_ENTERPRISE_S';
const CONFIG_MAX_QUERY_DURATION_PREMIUM_S = 'MAX_QUERY_DURATION_PREMIUM_S';
const CONFIG_MAX_QUERY_DURATION_FREE_S = 'MAX_QUERY_DURATION_FREE_S';
const CONFIG_KEYS = [
  CONFIG_HTTP_API,
  CONFIG_WS_API,
  CONFIG_POST_REGISTRATION_VERIFICATION_REDIRECT,
  CONFIG_DISPLAY_RESPONSIVE_CONTAINER,
  CONFIG_REFRESH_AUTH_INTERVAL_S,
  CONFIG_MAX_QUERY_DURATION_ENTERPRISE_S,
  CONFIG_MAX_QUERY_DURATION_PREMIUM_S,
  CONFIG_MAX_QUERY_DURATION_FREE_S,
];

/**
 * Runtime configuration parameters
 */
export interface Config {
  httpBaseUrl: string;
  wsBaseUrl: string;
  postRegistrationVerificationRedirect: string;
  displayResponsiveContainer: boolean;
  refreshAuthIntervalS: number;
  maxQueryDurationEnterpriseS: number;
  maxQueryDurationPremiumS: number;
  maxQueryDurationFreeS: number;
}

/**
 * Returns true is the environment contains the expected keys, otherwise false.
 */
const isEnvValid = (): boolean => {
  for (const key of CONFIG_KEYS) {
    if (env(key) === undefined) {
      console.error(`Missing configuration key: ${key}`);
      return false;
    }
  }
  return true;
};

/**
 * Attempts to load the configuration parameters from the environment.
 */
export const loadConfig = (): Config | null => {
  if (!isEnvValid()) {
    return null;
  }

  return {
    httpBaseUrl: env(CONFIG_HTTP_API),
    wsBaseUrl: env(CONFIG_WS_API),
    postRegistrationVerificationRedirect: env(CONFIG_POST_REGISTRATION_VERIFICATION_REDIRECT),
    displayResponsiveContainer: env(CONFIG_DISPLAY_RESPONSIVE_CONTAINER) === 'true',
    refreshAuthIntervalS: parseFloat(env(CONFIG_REFRESH_AUTH_INTERVAL_S)),
    maxQueryDurationEnterpriseS: parseFloat(env(CONFIG_MAX_QUERY_DURATION_ENTERPRISE_S)),
    maxQueryDurationPremiumS: parseFloat(env(CONFIG_MAX_QUERY_DURATION_PREMIUM_S)),
    maxQueryDurationFreeS: parseFloat(env(CONFIG_MAX_QUERY_DURATION_FREE_S)),
  };
};
