import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Theme } from '../../theme';

const getOutlinedCss = () => css`
  display: flex;
  color: white;
  height: 39px;
  line-height: 39px;
  padding: 0 22px;
  box-shadow: 0 0 0 2px #fff inset;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 4.5px;
  transition: all 0.2s ease-in;
  align-items: center;
  border: none;
  background: transparent;
  gap: 4px;
  white-space: nowrap;

  &:hover,
  &.active {
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const getStandardCss = ({ theme }: { theme: Theme }) =>
  css`
    display: flex;
    align-items: center;
    background: ${theme.colors.background.blue};
    padding: 16px 30px;
    color: white;
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 900;
    gap: 12px;

    &:hover {
      background: ${theme.colors.background.darkBlue};
    }
  `;

const getPrimaryCss = ({ theme }: { theme: Theme }) => css`
  display: flex;
  outline: none;
  border: none;
  background: ${theme.colors.green};
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  padding: 12px 24px;
  justify-content: center;
  line-height: 16px;

  &:hover {
    background: ${theme.colors.darkGreen};
  }
`;
const getSecondaryCss = ({ theme }: { theme: Theme }) => css`
  display: flex;
  outline: none;
  border: none;
  background: ${theme.colors.background.blue};
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  padding: 12px 24px;
  justify-content: center;
  line-height: 16px;

  &:hover {
    background: ${theme.colors.background.darkBlue};
  }
`;

const StyledLink = styled(Link)<{ variant: 'outlined' | 'standard' | 'primary' | 'secondary' }>`
  ${(props) => props.variant === 'outlined' && getOutlinedCss()}

  ${(props) => props.variant === 'standard' && getStandardCss(props)}

  ${(props) => props.variant === 'primary' && getPrimaryCss(props)}
`;

const StyledButton = styled.button.attrs({ className: 'rv-button' })<{
  variant: 'outlined' | 'standard' | 'primary' | 'secondary';
}>`
  ${(props) => props.variant === 'outlined' && getOutlinedCss()}

  ${(props) => props.variant === 'standard' && getStandardCss(props)}

  ${(props) => props.variant === 'primary' && getPrimaryCss(props)}

  ${(props) => props.variant === 'secondary' && getSecondaryCss(props)}

  .spinner-border {
    width: 16px;
    height: 16px;
  }
`;

const StyledAnchor = styled.a<{ variant: 'outlined' | 'standard' | 'primary' | 'secondary' }>`
  ${(props) => props.variant === 'outlined' && getOutlinedCss()}

  ${(props) => props.variant === 'standard' && getStandardCss(props)}

  ${(props) => props.variant === 'primary' && getPrimaryCss(props)}
`;

const StyledNavLink = styled(NavLink)<{ variant: 'outlined' | 'standard' | 'primary' | 'secondary' }>`
  ${(props) => props.variant === 'outlined' && getOutlinedCss()}

  ${(props) => props.variant === 'standard' && getStandardCss(props)}

  ${(props) => props.variant === 'primary' && getPrimaryCss(props)}
`;

interface ButtonProps {
  children: React.ReactNode;
  to?: string;
  variant?: 'outlined' | 'standard' | 'primary' | 'secondary';
  onClick?: () => void;
  isNavLink?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  to,
  variant = 'outlined',
  onClick,
  isNavLink = false,
  disabled = false,
  loading = false,
}: ButtonProps) => {
  if (to) {
    if (isNavLink) {
      return (
        <StyledNavLink to={to} variant={variant}>
          {children}
        </StyledNavLink>
      );
    }

    if (to.includes('http')) {
      return (
        <StyledAnchor href={to} variant={variant}>
          {children}
        </StyledAnchor>
      );
    }

    return (
      <StyledLink to={to} variant={variant}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton variant={variant} onClick={onClick} disabled={disabled}>
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default Button;
