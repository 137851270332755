import styled from 'styled-components';

export const Header = styled.header`
  min-height: 700px;
  background ${(props) => props.theme.colors.background.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    min-height: 450px;
  }
`;

export const HeaderTopHero = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  h1 {
    font-size: 70px;
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 32px;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 80px;
      height: auto;
    }
  }
`;

export const Description = styled.h2`
  color: ${(props) => props.theme.colors.white};
  margin-top: 40px;
  margin-bottom: 14px;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;

  h3 {
    margin-bottom: 14px;
  }

  p {
    margin-bottom: 20px;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 20px;
  }

  img {
    width: 400px;
    height: auto;
    max-width: 100%;
  }
`;

export const FeatureSection = styled.div`
  padding-top: 112px;
  padding-bottom: 112px;
`;
