import { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaSignInAlt, FaUserCircle, FaBars } from 'react-icons/fa';
import { useMedia, useClickAway } from 'react-use';
import Button from '../common/Button';
import { useCloudContext } from '../../libs/CloudContext';
import { Nav, NavList, NavListItem, SidebarOverlay } from './TopNav.styled';

const TopNav = () => {
  const ref = useRef<HTMLUListElement>(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const { auth, cloudClient } = useCloudContext();
  const isMobile = useMedia('(max-width: 768px)');
  useClickAway(ref, () => {
    setOpenSidebar(false);
  });

  return (
    <Nav>
      <SidebarOverlay isOpen={openSidebar} />
      <Container className="d-flex justify-content-between align-items-center">
        {isMobile && <FaBars color="#fff" onClick={() => setOpenSidebar(true)} style={{ cursor: 'pointer' }} />}
        <NavList isOpen={openSidebar} ref={ref}>
          <NavListItem>
            <NavLink to="/home">Home</NavLink>
          </NavListItem>
          <NavListItem>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </NavListItem>
          <NavListItem>
            <NavLink to="/reports">Report</NavLink>
          </NavListItem>
          {isMobile && (
            <NavListItem>
              <NavLink to="/account">Account</NavLink>
            </NavListItem>
          )}
        </NavList>
        <div className="d-flex gap-2">
          {auth ? (
            <>
              {!isMobile && (
                <Button isNavLink to="/account">
                  <FaUserCircle />
                  Account
                </Button>
              )}
              <Button onClick={() => cloudClient.signOut()}> Sign Out ({auth.sub})</Button>
            </>
          ) : (
            <>
              <Button isNavLink to="/login">
                <FaSignInAlt />
                Sign In
              </Button>
              <Button to="/registration">Sign Up</Button>
            </>
          )}
        </div>
      </Container>
    </Nav>
  );
};

export default TopNav;
