import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CloudProvider } from './libs/CloudContext';
import theme from './theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-datetime/css/react-datetime.css';
import './index.css';

const muiTheme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <CloudProvider>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <App />
          </HashRouter>
        </ThemeProvider>
      </MuiThemeProvider>
    </CloudProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
